body {
  background: #121212 !important;
}

#name{
    color: 	#4682B4;
    font-family: 'Maven Pro', sans-serif;
    font-size: 11.5vw;
    font-weight: 800;
    line-height: 20vh;
}

#menu-button{
    margin-left: 30px;
    margin-right: 30px;
}

.circle {
    border-image: none;
    border-radius: 50% 50% 50% 50%;
    border-style: solid;
    border-width: 0px;
    height: 34vw;
    width: 34vw;
    overflow: hidden;
    margin-left: 50px;
  }
  
  img {
    height: auto;
    width: 100%;
    margin: auto;
  }
  
  #primary{
    color: #ADD8E6;
    font-family: 'Maven Pro', sans-serif;
    font-size: 3.3vw;
    font-weight: 800;
    margin-bottom: 1vh
  }

  #secondary{
    color: #ADD8E6;
    font-family: 'Maven Pro', sans-serif;
    font-size: 3.3vw;
    font-weight: 800;
    margin-bottom: 1vh;
    margin-top: 2vh
  }

  .container-fluid{
    margin-top: 1vh;
  }

  #small{
    font-family: 'Maven Pro', sans-serif;
    font-size: 2.3vh;
    font-weight: normal;
    color: #4682B4 ;
    margin-top: 2.3vh;
  }
  
  #row1{
    margin-left: 10vw;
  }


  #container1{
    margin-top: 100px;
  }

  #about-container{
    padding-left: 12vw;
    padding-right: 12vw;
  }

  p{
    font-family: 'Maven Pro', sans-serif;
    font-size: 1.3vw;
    font-weight: normal;
    color: #4682B4 ;
    margin-top: 2vw;
  }

  #skills{
    font-family: 'Maven Pro', sans-serif;
    font-size: 1.3vw;
    font-weight: normal;
    color: #4682B4 ;
    margin-top: 0.3vh;
    text-align: center;
  }

  li{
    font-family: 'Maven Pro', sans-serif;
    font-size: 1.1vw;
    font-weight: normal;
    color: #4682B4 ;
  }

  .centered{
    display: flex;
    justify-content: center;
    margin-bottom: 1.5vh;
  }

  #centered{
    display: flex;
    justify-content: center;
    margin-bottom: 2vh;
  }

  .cards{
    margin: 30px 30px 30px 30px;
  }

  #field{
    display: flex;
    justify-content: center;
    margin-bottom: 10px;
  }

  #contact-form{
    display: flex;
    justify-content: center;
    margin-bottom: 10px
  }

  #contact-field{
    display: flex;
    justify-content: center;
    margin-bottom: 20px
  }

  #socials{
    position: absolute;
    left: 20%;
    right: 20%;
    bottom: 0;
  }

  .logo{
    max-width: 30px;
    height: 30px
  }